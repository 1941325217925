.not-found {
    background-color: #252735 !important;
}

.setactive {
    color: #fff !important;
    background: #edcb6d !important;
}

.right-side {
    float: right;
}

.error-text {
    color: #b90707
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    text-align: center;
    font-size: 14px;
}

.bg-login {
    background: url("./images/bg.jpg") 50% 50% no-repeat;
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.login-box {
    /* width: 340px;
    margin: 5% auto;
    background: rgba(255,255,255,0.8);
    padding: 25px;
    box-shadow: 0 10px 10px 5px rgba(0,0,0,0.2);
    border-radius: 5px; */
    width: 340px;
    margin: 20vh auto 0;
    background: rgba(255, 255, 255, 0.8);
    padding: 25px;
    box-shadow: 0 10px 10px 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.logo-company {
    text-align: center;
    margin-bottom: 15px;
}

.btn {
    padding: 0.5rem 1.5rem;
}

.btn-primary {
    /* border-radius: 0; */
    background-color: #000;
    border-color: #000;
}

.btn-success,
.btn-warning {
    background-color: #000 !important;
    border-color: #000 !important;
}

.btn-warning {
    background-color: #edcb6d !important;
    border-color: #edcb6d !important;
}

.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
    color: #fff;
    background-color: #a75237 !important;
    border-color: #a75237 !important;
}

.btn-success:hover,
.btn-success:active,
.btn-success:focus {
    color: #fff;
    background-color: #a75237 !important;
    border-color: #a75237 !important;
}

.text-primary {
    color: #000 !important;
}


/* NEW CSS ADDED ON 31-08-2020 */

.login-box.registration-box {
    width: 60%;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #000 !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    color: #fff;
    background-color: #a75237 !important;
    border-color: #a75237 !important;
}

.c-sidebar .c-sidebar-brand,
.c-sidebar .c-sidebar-header {
    background: rgb(255 255 255 / 0.8) !important;
}

.c-sidebar .c-sidebar-nav-link i {
    margin: 0 10px 0 0;
    width: 30px;
    height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #a75237 !important;
}

.c-sidebar {
    background: #232323;
}

.c-avatar {
    border-radius: 50%;
    overflow: hidden;
}

.c-avatar img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-object-fit: cover;
    -moz-object-fit: cover;
    height: 100%;
    overflow: hidden;
    object-position: 50% 50%;
    -webkit-object-position: 50% 50%;
    -o-object-position: 50% 50%;
    -moz-object-position: 50% 50%;
    border-radius: 0 !important;
}

.c-header .c-header-toggler {
    outline: none;
}

.dash-box {
    background: #dce775;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 200px;
    justify-content: center;
    box-shadow: 0 0 10px 0px rgb(0 0 0 / 20%);
}

.light-green-bg {
    background: #9ccc65 !important;
}

.dash-box h4 {
    text-transform: uppercase;
    margin: 0 0 5px;
    font-size: 18px;
    line-height: normal;
    color: rgb(35, 35, 35);
    position: relative;
    font-weight: 400;
}

.dash-box h4::after {
    content: "";
    width: 250px;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: -8px;
    left: calc(50% - 125px);
}

.dash-value {
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
    color: #000;
}

.cal-icon .react-bootstrap-daterangepicker-container {
    display: block !important;
}

.mt-adjust {
    margin-top: 27px;
}

.cal-icon .react-bootstrap-daterangepicker-container {
    position: relative;
}

.cal-icon .react-bootstrap-daterangepicker-container::before {
    content: "\f073";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #ccc;
    font-size: 18px;
    padding-right: 0.5em;
    position: absolute;
    top: 8px;
    right: 5px;
    pointer-events: none;
}

.cal-icon .react-bootstrap-daterangepicker-container input {
    padding-right: 40px;
}

.page-item.active .page-link {
    background: #a75237;
    border-color: #a75237;
}

.form-control {
    min-height: 38px;
}

.uppersection .react-datepicker-wrapper,
.lowersection .react-datepicker-wrapper {
    width: 100%;
}

.modal-header,
.modal-header .close {
    padding: 1rem 1rem;
    background: #a75237;
    color: #fff;
}

.modal-h1 {
    font-size: 18px;
}

.modal-email {
    display: inline-block;
}

.modal-email a {
    display: inline-block;
    font-weight: 600;
    color: #a75237;
}

.lowersection h2 {
    font-size: 18px;
}

.permission-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.permission-list li {
    display: block;
}

.permission-list li input {
    margin: 0 5px 0 0;
    position: relative;
    top: 2px;
}

.permission-list li span {
    display: inline-block;
}

.permission-list li span.typename {
    font-weight: 600;
}

.add-file-field {
    background: #000000;
    border: 0 none;
    padding: 10px 25px;
    color: #fff;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
}

.leave-input {
    display: flex;
}

.leave-input label {
    margin: 0 5px 0 0;
}

.view.files {
    width: 10%;
}

.view.files span {
    cursor: pointer;
    background: #a75237;
    color: #fff;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
}

.view-modal label {
    font-weight: 600;
    margin: 0;
    font-size: 11px;
    /* text-transform: uppercase; */
    letter-spacing: 1px;
}
.rush{
   font-weight: bold;
   color: #f9b115;
}
.super-rush{
    font-weight: bold;
    color: #e55353;
 }

.view-modal .datavalue {
    background: #e8e8e8;
    padding: 7px 10px;
    border-radius: 5px;
    min-height: 35px;
}

.view-modal ul {
    list-style: none;
    padding: 0;
    margin: 0 0 15px;
}

.view-modal ul li span {
    display: inline-block;
}

.view-modal ul li span.name {
    font-weight: 600;
}

.uppersection,
.lowersection {
    margin: 0 -15px;
}


/* MASSGE CSS STARt */

.inbox_people {
    background: #f8f8f8 none repeat scroll 0 0;
    float: left;
    overflow: hidden;
    width: 40%;
    border-right: 1px solid #c4c4c4;
}

.inbox_msg {
    border: 1px solid #c4c4c4;
    clear: both;
    overflow: hidden;
}

.top_spac {
    margin: 20px 0 0;
}

.recent_heading {
    float: left;
    width: 40%;
}

.srch_bar {
    display: inline-block;
    text-align: right;
    width: 60%;
}

.headind_srch {
    padding: 10px 29px 10px 20px;
    overflow: hidden;
    border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
    color: #232323;
    font-size: 18px;
    margin: 0 0 0;
    line-height: normal;
}

.srch_bar input {
    border: 1px solid #cdcdcd;
    border-width: 0 0 1px 0;
    width: 80%;
    padding: 2px 0 4px 6px;
    background: none;
}

.srch_bar input:focus {
    outline: none;
}

.srch_bar .input-group-addon button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    padding: 0;
    color: #707070;
    font-size: 18px;
}

.srch_bar .input-group-addon button:focus {
    outline: none;
}

.srch_bar .input-group-addon {
    margin: 0 0 0 -27px;
}

.chat_ib h5 {
    font-size: 12px;
    color: #464646;
    margin: 0 0 8px 0;
    display: flex;
    justify-content: space-between;
}

.chat_ib h5 span {
    font-size: 13px;
    float: right;
}

.chat_ib p {
    font-size: 14px;
    color: #989898;
    margin: auto
}

.incoming_msg {
    display: flex;
}

.chat_img {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.chat_img img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-object-fit: cover;
    -moz-object-fit: cover;
    height: 100%;
    overflow: hidden;
    object-position: 50% 50%;
    -webkit-object-position: 50% 50%;
    -o-object-position: 50% 50%;
    -moz-object-position: 50% 50%;
    border-radius: 0 !important;
}

.chat_ib {
    float: left;
    padding: 0 0 0 15px;
    width: calc(100% - 40px);
}

.chat_people {
    overflow: hidden;
    clear: both;
}

.chat_list {
    border-bottom: 1px solid #c4c4c4;
    margin: 0;
    padding: 18px 16px 10px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.inbox_chat {
    max-height: 75vh;
    min-height: 75vh;
    overflow-y: auto;
}

.active_chat,
.chat_list:hover {
    background: #ebebeb;
}

.incoming_msg_img {
    display: inline-block;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
}

.incoming_msg_img img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-object-fit: cover;
    -moz-object-fit: cover;
    height: 100%;
    overflow: hidden;
    object-position: 50% 50%;
    -webkit-object-position: 50% 50%;
    -o-object-position: 50% 50%;
    -moz-object-position: 50% 50%;
    border-radius: 0 !important;
}

.received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: calc(100% - 50px);
}

.received_withd_msg p {
    background: #edcb6d none repeat scroll 0 0;
    border-radius: 10px 10px 10px 0;
    color: #232323;
    font-size: 14px;
    margin: 0;
    padding: 10px 15px;
    width: 100%;
}

.time_date {
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
}

.received_withd_msg {
    width: 57%;
}

.mesgs {
    float: left;
    padding: 30px 15px 0 25px;
    width: 60%;
}

.sent_msg p {
    background: #a75237;
    border-radius: 10px 10px 0 10px;
    font-size: 14px;
    margin: 0;
    color: #fff;
    padding: 10px 15px;
    width: 100%;
}

.outgoing_msg {
    overflow: hidden;
    margin: 26px 0 26px;
}

.sent_msg {
    float: right;
    width: 46%;
}

.input_msg_write input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: #232323;
    font-size: 15px;
    min-height: 48px;
    width: 100%;
}

.input_msg_write input:focus {
    outline: none;
}

.type_msg {
    border-top: 1px solid #c4c4c4;
    position: relative;
}

.msg_send_btn {
    background: #000 none repeat scroll 0 0;
    border: medium none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    height: 33px;
    position: absolute;
    right: 0;
    top: 11px;
    width: 33px;
}

.msg-attach{
    background: #000 none repeat scroll 0 0;
    border: medium none;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    height: 33px;
    position: absolute;
    right: 35px;
    top: 11px;
    width: 33px;
}

.msg_send_btn:focus,
.msg_send_btn:active,
.msg_send_btn:hover {
    outline: none;
}

.messaging {
    padding: 0 0px 0;
}

.msg_history {
    max-height: 70vh;
    overflow-y: auto;
    min-height: 70vh;
    scroll-behavior: smooth;
}


.iname {
    width: 60px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 50px;
    /* line-height:50px; */
}

.unread {
    float: right;
    width: 25px;
    height: 25px;
    background-color: darkred;
    color: #fff;
    border-radius: 27px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
}

.withordr {
    float: right;
    font-weight: 600;
}

.withordr i {
    display: inline-block;
    margin: 0 5px 0 0;
}

.cpointer {
    cursor: pointer;
}

.city .css-2b097c-container,
.state .css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    width: calc(100% - 42px);
}

.my-menu__control {
    border-radius: 0 4px 4px 0 !important;
}

.city .input-group-text,
.state .input-group-text {
    border-radius: 4px 0 0 4px;
}

.city .error-text, .state .error-text {
    color: #b90707;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 20px;
    font-size: 11px;
}
.showupfiles{
    padding: 0px !important;
    margin:15px 0px !important;
    list-style: none !important;
}
.showupfiles .file-name{
  
    margin: 5px !important;
    display: inline-block !important;
    color: black !important;
}
.showupfiles .file-name a{
    color: black !important;
}
.tunread-count{
    width: 25px;
    height: 25px;
    background-color: darkred;
    color: #fff;
    border-radius: 27px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-left:10px;
}
.prnt{
    padding: 2px 5px 2px 5px;
    color: #e6ecf1;
    background: #deae42;
    margin-left: 5px;
    border-radius: 5px;
    cursor: pointer;
}

/* .prnt:hover{
    background: #a75237;
} */
.pr-icon{
    display: flex;
    justify-content: space-between;
}
.height60{
    height:60px;
}
/* MASSAGE CSS END */

@media (max-width: 1599px) and (min-width: 1025px) {
    .inbox_chat {
        max-height: 70vh;
        min-height: 70vh;
    }
    .msg_history {
        max-height: 65vh;
        min-height: 65vh;
    }
    .registration-page + .footer {
        position: static;
        margin-top: 25px;
    }
}

@media(max-width: 550px) {
    .msg-with-list .inbox_people {
        width: 100% !important;
        border-right: 0 none !important;
    }
    .msg-with-list .chat_people .chat_ib {
        display: none !important;
    }
    .msg-with-list .recent_heading {
        width: 100% !important;
    }
    .msg-with-list .chat_people {
        display: inline-block;
        margin: 0 5px 0 0;
    }
    .msg-with-list .inbox_chat {
        max-height: 75px !important;
        min-height: 75px !important;
        max-width: 100%;
        overflow-x: auto !important;
    }
    .msg-with-list .chat_list {
        display: flex;
        flex-wrap: nowrap;
        width: max-content;
    }
    .msg-with-list .mesgs {
        width: 100% !important;
        padding: 15px !important;
    }
}